<template>
  <v-autocomplete
    v-model="productSelected"
    :items="productList"
    :name="Math.random()"
    :search-input.sync="query"
    :placeholder="placeholder"
    :class="[classAdd]"
    :clearable="clearable"
    item-text="name"
    return-object
    outlined
    autocomplete="off"
    dense
    hide-details
  />
</template>

<script>
let searchTimeout = null
export default {
  props: {
    value: {
      type: Object,
      default: () => null
    },
    placeholder: {
      type: String,
      default: 'Search Product'
    },
    returnedData: {
      type: Object,
      default: () => null
    },
    classAdd: {
      type: String,
      default: 'append-0 rounded-xl'
    },
    unclear: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    withPackage: {
      type: Boolean,
      default: false
    },
    isMerchandise: {
      type: Boolean,
      default: false
    },
    isAdditional: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    query: '',
    productList: [],
    productSelected: null
  }),
  watch: {
    value (v) {
      if (v) {
        if (!this.unclear) {
          this.productSelected = null
        }
      }
    },
    productSelected (v) {
      if (v) {
        const d = Object.assign({}, v)
        if (this.returnedData) {
          d.returned_data = Object.assign({}, this.returnedData)
        }
        this.$emit('input', d)
      } else {
        this.$emit('input', v)
      }
      if (!this.unclear) {
        setTimeout(() => {
          this.productList = []
          this.productSelected = null
          this.query = ''
        }, 300)
      }
    },
    query (v) {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.PRODUCT_GET(v)
      }, 300)
    }
  },
  methods: {
    PRODUCT_GET (q) {
      const withPackage = this.withPackage ? '' : '&nopackage=1'
      const isMerchandise = this.isMerchandise ? '&is_merchandise=1' : ''
      const isAdditional = this.isAdditional ? '&is_additional=1' : ''
      const query = '?sortby=name&sort=ASC&page=1&q=' + (q || '') + (withPackage) + (isMerchandise) + (isAdditional)
      this.$store.dispatch('PRODUCT_GET', query)
        .then((res) => {
          if (res.status) {
            this.productList = res.data.data.data
          } else {
            this.productList = []
          }
        })
    }
  }
}
</script>
